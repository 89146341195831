<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card class="px-5 py-3" style="margin-top:10px">
      <v-card-text>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>検索条件</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="disease" label="症状名、障害名"></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          style="margin-top:10px; font-size:12px; text-align:right"
          v-if="search_conditions_text !==''"
        >
          <span>{{search_conditions_text}}</span>
          <v-icon
            id="copy_icon"
            title="検索条件をコピー"
            style="margin-left:5px;margin-right:10px"
            @click="copyURLToClipboard()"
          >mdi-content-copy</v-icon>
          <span>{{" 検索結果 : " + filteredItems.length +"件" }}</span>
          <input id="dummy" style="display:none" />
        </div>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          mobile-breakpoint="0"
          style="white-space: pre;"
          :footer-props="footer_props"
          :loading="items.length === 0"
        >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
          <template v-slot:item="i">
            <!-- Since v-slot:item overrides how each row is rendered, I rebuild the row starting from <tr>. This allows me to add a class to <tr> based on any condition I want (in this case, the calorie count) -->
            <tr>
              <td>{{i.item.name}}</td>
              <td>{{i.item.countInMale}}</td>
              <td>{{i.item.countInFemale}}</td>
              <td>{{i.item.count}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<style>
table {
  table-layout: fixed;
}
.v-data-table td {
  white-space: pre-line;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 8px !important;
}
</style>

<script>
import axios from 'axios'
export default {
  name: 'Dashboard',

  data() {
    return {
      panel: 0,
      search_conditions_text: '',
      disease: '',
      footer_props: {
        'items-per-page-options': [10, 50, 100, 200],
        showFirstLastPage: true,
      },
      headers: [
        {
          sortable: true,
          text: '症状名、障害名',
          value: 'name',
        },
        {
          sortable: true,
          text: '件数(男性)',
          value: 'countInMale',
          width: '100px',
        },
        {
          sortable: true,
          text: '件数(女性)',
          value: 'countInFemale',
          width: '100px',
        },
        {
          sortable: true,
          text: '件数',
          value: 'count',
          width: '100px',
        },
      ],
      items: [],
    }
  },

  computed: {
    filteredItems() {
      var conditions = []
      this.clearSearchConditionText()
      var text = ''
      if (this.disease) {
        if (text !== '') text += ' / '
        text += '症状名、障害名 : ' + this.disease + 'を含む'
        conditions.push(this.filterByDisease)
      }

      if (conditions.length > 0) {
        text = '検索条件 : [ ' + text + ' ]'
        this.setSearchConditionText(text)
        return this.items.filter(item => {
          return conditions.every(condition => {
            return condition(item)
          })
        })
      }

      return this.items
    },
  },

  mounted() {
    if (this.$route.query.name) {
      this.disease = this.$route.query.name
    }

    this.getJson()
  },

  watch: {
    $route(to, from) {
      this.getJson()
    },
  },

  methods: {
    clearSearchConditionText() {
      this.search_conditions_text = ''
    },
    setSearchConditionText(text) {
      this.search_conditions_text = text
    },

    filterByDisease(item) {
      return item.name.includes(this.disease)
    },

    getJson() {
      this.items = []
      var names = []
      var jsonFileName = 'approved.json'
      axios.get('/json/' + jsonFileName).then(
        function(res) {
          for (var row of res.data) {
            var splited = row.name.split('、')
            for (var name of splited) {
              if (name === '') continue
              if (!names.includes(name)) {
                names.push(name)
                var countInMale = 0
                var countInFemale = 0
                if (row.gender === '男性') countInMale++
                else if (row.gender === '女性') countInFemale++

                this.items.push({
                  name: name,
                  countInMale: countInMale,
                  countInFemale: countInFemale,
                  count: 1,
                })
              } else {
                var index = names.indexOf(name)
                if (row.gender === '男性') this.items[index].countInMale++
                else if (row.gender === '女性') this.items[index].countInFemale++

                this.items[index].count++
              }
            }
          }
          this.items.sort(function(a, b) {
            return a.name < b.name ? -1 : 1
          })
        }.bind(this),
      )
    },

    copyURLToClipboard() {
      var url = window.location.href.split('?')[0] + '?'

      var param = ''
      if (this.disease) {
        param += '&name=' + this.disease
      }

      // clipboardがhttpでは動作しないため、別のやり方で回避
      // navigator.clipboard.writeText(url + param)
      const newInput = document.createElement('input')
      newInput.type = 'text'
      newInput.value = url + param
      const dummy = document.getElementById('dummy')
      dummy.parentNode.insertBefore(newInput, dummy.nextSibling)
      newInput.select()
      document.execCommand('copy')
      newInput.parentNode.removeChild(newInput)
      document.getElementById('copy_icon').focus()
    },
  },
}
</script>
